<template>
    <div class="content-body">
        <div class="header">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item :to="{ path: '/practice/index' }">实操题</el-breadcrumb-item>
                <el-breadcrumb-item>添加粉丝特征</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">添加粉丝特征</div>
            </div>
            <div class="step-item" :class="{current: [2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content create-table" v-if="stepActive===1">
            <div class="create-content-top">
                <div class="create-content-left">
                    <el-button type="primary" plain @click="importFanCharacteristics" style="margin-right: 10px">导入粉丝特征</el-button>
                    <input type="file" ref="excelInput" accept="excel/*" title="" @change="changeExcel($event)" style="display: none;">
                    <el-button type="primary" plain @click="exportFanCharacteristics">导出粉丝特征</el-button>
                    <el-button type="primary" plain @click="downloadTemplate">下载模板</el-button>
                </div>
                <div class="create-content-right">
                    <label class="label-name">粉丝特征名称</label>
                    <el-input type="text" v-model="s_name" placeholder="请输入粉丝特征名称" class="name-input"></el-input>
                </div>
            </div>
            <el-table :data="fanCharacteristicsTable" style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%"  size="medium"
                      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                      :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                      class="customTable" :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                <el-table-column prop="name" align="center" label="客户名称"></el-table-column>
                <el-table-column prop="sex_value" align="center" label="性别"></el-table-column>
                <el-table-column prop="age" align="center" label="年龄"></el-table-column>
                <el-table-column prop="area_value" align="center" label="地区"></el-table-column>
                <el-table-column prop="member_level_value" align="center" label="会员等级"></el-table-column>
                <el-table-column prop="purchasing_power_value" align="center" label="购买力"></el-table-column>
                <el-table-column prop="promotion_sensitivity_value" align="center" label="促销敏感度"></el-table-column>
                <el-table-column prop="comment_sensitivity_value" align="center" label="评论敏感度"></el-table-column>
                <el-table-column prop="fan_type_value" align="center" label="粉丝类型"></el-table-column>
                <el-table-column label="操作" align="center" width="120px">
                    <template slot-scope="scope">
                        <el-link :underline="false" type="success" @click="editFansBtn(scope.row, scope.$index)">编辑</el-link>
                        <el-link :underline="false" type="danger" @click="delBtn(scope.$index, scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="add-footer">
                <el-button type="primary" plain @click="addFanCharacteristic">添加粉丝特征</el-button>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="success-content">
                <img src="../../../assets/images/admin/success.png" alt="">
                <p class="text">创建成功</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="fanCharacteristicsPrevBtn"  v-if="stepActive === 1">上一步</el-button>
            <el-button @click="fanCharacteristicsPrevBtn" type="primary" v-if="stepActive === 2">返回列表</el-button>
            <el-button type="primary"  @click="fanCharacteristicsNextBtn" v-if="stepActive === 1">下一步</el-button>
        </div>
        <el-dialog title="添加粉丝特征"
                   :visible.sync="addFanCharacteristicsDialog" width="500px"
                   :close-on-click-modal="false"
                   @close="handleCloseAddDialog"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-form :model="fanCharacteristicsForm" ref="fanCharacteristicsForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="客户名称" prop="name" class="add-item">
                        <el-input v-model="fanCharacteristicsForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex" class="add-item">
                        <el-select v-model="fanCharacteristicsForm.sex" placeholder="请选择性别" @change="selectSex">
                            <el-option
                                    v-for="item in sexList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年龄" prop="age" class="add-item">
                        <el-input-number
                                v-model="fanCharacteristicsForm.age"
                                :min="1"
                                :max="100"
                                :controls="false"
                                :precision="0"
                                style="width: 100%">
                        </el-input-number>
                    </el-form-item>
                    <el-form-item label="地区" prop="member_level" class="add-item">
                        <!-- <el-select v-model="fanCharacteristicsForm.area_id" placeholder="请选择地区" @change="selectArea">
                            <el-option
                                    v-for="item in allAreaList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select> -->
                        <el-cascader v-model="fanCharacteristicsForm.area_id" :options="allAreaList" :show-all-levels="false" :props="areaProps"></el-cascader>
                    </el-form-item>
                    <el-form-item label="会员等级" prop="member_level" class="add-item">
                        <el-select v-model="fanCharacteristicsForm.member_level" placeholder="请选择会员等级" @change="selectMember">
                            <el-option
                                    v-for="item in member_levelList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="购买力" prop="purchasing_power" class="add-item">
                        <el-select v-model="fanCharacteristicsForm.purchasing_power" placeholder="请选择购买力" @change="selectPurchasing_power">
                            <el-option
                                    v-for="item in purchasing_powerList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="促销敏感度" prop="promotion_sensitivity" class="add-item">
                        <el-select v-model="fanCharacteristicsForm.promotion_sensitivity" placeholder="请选择促销敏感度" @change="selectPromotion_sensitivity">
                            <el-option
                                    v-for="item in promotion_sensitivityList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评论敏感度" prop="comment_sensitivity" class="add-item">
                        <el-select v-model="fanCharacteristicsForm.comment_sensitivity" placeholder="请选择评论敏感度" @change="selectComment_sensitivity">
                            <el-option
                                    v-for="item in comment_sensitivityList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="粉丝类型" prop="fan_type" class="add-item">
                        <el-select v-model="fanCharacteristicsForm.fan_type" placeholder="请选择粉丝类型" @change="selectFan_type">
                            <el-option
                                    v-for="item in fan_typeList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleCloseAddDialog">取消</el-button>
                        <el-button type="primary" @click="submitForm">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {fanSave, fanImport, fanExport, fan, fan1, getFansArea} from '@/utils/apis'

    export default {
        name: "FanCharacteristicsCreate",
        data() {
            return {
                //步骤
                stepActive: 1,
                //粉丝特征列表
                fanCharacteristicsTable: [],
                //粉丝特征名称
                s_name: '',
                //粉丝特征id
                fanCharacteristicsId: Number(this.$route.query.id) || 0,
                //导入粉丝特征
                fanCharacteristics_excel: '',
                excelFile: '',
                excelFileName: '',
                //添加粉丝特征弹窗
                addFanCharacteristicsDialog: false,
                //添加粉丝特征表单
                fanCharacteristicsForm: {
                    id: null,
                    name: '',
                    sex: '',
                    sex_value: '',
                    age: null,
                    area_id: null,
                    area_value: '',
                    member_level: '',
                    member_level_value: '',
                    purchasing_power: '',
                    purchasing_power_value: '',
                    promotion_sensitivity: '',
                    promotion_sensitivity_value: '',
                    comment_sensitivity: '',
                    comment_sensitivity_value: '',
                    fan_type: '',
                    fan_type_value: '',
                },
                //性别
                sexList: [
                    {
                        id: 0,
                        label: '女'
                    },
                    {
                        id: 1,
                        label: '男'
                    }
                ],
                areaProps: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                //地区
                allAreaList: [
                    // {
                    //     id: 1,
                    //     label: '北京'
                    // },
                    // {
                    //     id: 2,
                    //     label: '广西'
                    // },
                    // {
                    //     id: 3,
                    //     label: '江西'
                    // },
                    // {
                    //     id: 4,
                    //     label: '四川'
                    // },
                    // {
                    //     id: 5,
                    //     label: '海南'
                    // },
                    // {
                    //     id: 6,
                    //     label: '贵州'
                    // },
                    // {
                    //     id: 7,
                    //     label: '云南'
                    // },
                    // {
                    //     id: 8,
                    //     label: '西藏'
                    // },
                    // {
                    //     id: 9,
                    //     label: '陕西'
                    // },
                    // {
                    //     id: 10,
                    //     label: '甘肃'
                    // },
                    // {
                    //     id: 11,
                    //     label: '青海'
                    // },
                    // {
                    //     id: 12,
                    //     label: '宁夏'
                    // },
                    // {
                    //     id: 13,
                    //     label: '新疆'
                    // },
                    // {
                    //     id: 14,
                    //     label: '台湾'
                    // },
                    // {
                    //     id: 15,
                    //     label: '钓鱼岛'
                    // },
                    // {
                    //     id: 16,
                    //     label: '广东'
                    // },
                    // {
                    //     id: 17,
                    //     label: '湖南'
                    // },
                    // {
                    //     id: 18,
                    //     label: '上海'
                    // },
                    // {
                    //     id: 19,
                    //     label: '天津'
                    // },
                    // {
                    //     id: 20,
                    //     label: '重庆'
                    // },
                    // {
                    //     id: 21,
                    //     label: '河北'
                    // },
                    // {
                    //     id: 22,
                    //     label: '山西'
                    // },
                    // {
                    //     id: 23,
                    //     label: '河南'
                    // },
                    // {
                    //     id: 24,
                    //     label: '辽宁'
                    // },
                    // {
                    //     id: 25,
                    //     label: '吉林'
                    // },
                    // {
                    //     id: 26,
                    //     label: '黑龙江'
                    // },
                    // {
                    //     id: 27,
                    //     label: '湖北'
                    // },
                    // {
                    //     id: 28,
                    //     label: '福建'
                    // },
                    // {
                    //     id: 29,
                    //     label: '浙江'
                    // },
                    // {
                    //     id: 30,
                    //     label: '安徽'
                    // },
                    // {
                    //     id: 31,
                    //     label: '山东'
                    // },
                    // {
                    //     id: 32,
                    //     label: '江苏'
                    // },
                    // {
                    //     id: 33,
                    //     label: '内蒙古'
                    // },
                    // {
                    //     id: 34,
                    //     label: '港澳'
                    // }
                ],
                //会员等级
                member_levelList: [
                    {
                        id: 1,
                        label: '注册会员'
                    },
                    {
                        id: 2,
                        label: '铜牌会员'
                    },
                    {
                        id: 3,
                        label: '银牌会员'
                    },
                    {
                        id: 4,
                        label: '金牌会员'
                    },
                    {
                        id: 5,
                        label: '钻石会员'
                    }
                ],
                //购买力
                purchasing_powerList: [
                    {
                        id: 1,
                        label: '土豪'
                    },
                    {
                        id: 2,
                        label: '高级白领'
                    },
                    {
                        id: 3,
                        label: '小白领'
                    },
                    {
                        id: 4,
                        label: '蓝领'
                    },
                    {
                        id: 5,
                        label: '收入很少'
                    }
                ],
                //促销敏感度
                promotion_sensitivityList: [
                    {
                        id: 1,
                        label: '不敏感'
                    },
                    {
                        id: 2,
                        label: '轻度敏感'
                    },
                    {
                        id: 3,
                        label: '中度敏感'
                    },
                    {
                        id: 4,
                        label: '高度敏感'
                    },
                    {
                        id: 5,
                        label: '极度敏感'
                    }
                ],
                //评论敏感度
                comment_sensitivityList: [
                    {
                        id: 1,
                        label: '不敏感'
                    },
                    {
                        id: 2,
                        label: '轻度敏感'
                    },
                    {
                        id: 3,
                        label: '中度敏感'
                    },
                    {
                        id: 4,
                        label: '高度敏感'
                    },
                    {
                        id: 5,
                        label: '极度敏感'
                    }
                ],
                //粉丝类型
                fan_typeList: [
                    {
                        id: 1,
                        label: '新增粉丝'
                    },
                    {
                        id: 2,
                        label: '成交粉丝'
                    },
                    {
                        id: 3,
                        label: '两者都是'
                    }
                ],
                editIndex: null,
            }
        },
        mounted() {
            this.getAllarea();
            this.getFindFanCharacteristics();
        },
        methods: {
            getAllarea() {
                getFansArea().then((res) => {
                    this.allAreaList = res.data;
                }).catch((err) => {
                     console.log(err);
                });
            },
            //编辑粉丝特征
            getFindFanCharacteristics() {
                if (this.fanCharacteristicsId) {
                    let param = {
                        id: this.fanCharacteristicsId
                    }
                    fan(param).then((res) => {
                        this.s_name = res.data.name;
                        this.fanCharacteristicsTable = res.data.content;
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            },
            //上一步
            fanCharacteristicsPrevBtn() {
                this.$router.push({
                    path: '/practice/fanCharacteristics'
                })
            },
            //下一步(保存)
            fanCharacteristicsNextBtn() {
                if (this.fanCharacteristicsTable.length > 0) {
                    let formData = new FormData();
                    formData.append('name', this.s_name);
                    formData.append('content', JSON.stringify(this.fanCharacteristicsTable));
                    if (this.fanCharacteristicsId) {
                        formData.append('id', this.fanCharacteristicsId);
                    }
                    fanSave(formData).then((res) => {
                        this.stepActive = 2;
                        this.$message.success(res.msg);
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    this.$message.warning('请选择要添加的粉丝特征！');
                }
            },
            //添加粉丝特征
            addFanCharacteristic() {
                this.addFanCharacteristicsDialog = true;
            },
            //选择性别
            selectSex(val) {
                this.sexList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.sex_value = item.label;
                    }
                })
            },
            //选择地区
            selectArea(val) {
                this.allAreaList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.area_value = item.label;
                    }
                })
            },
            //选择会员等级
            selectMember(val) {
                this.member_levelList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.member_level_value = item.label;
                    }
                })
            },
            //选择购买力
            selectPurchasing_power(val) {
                this.purchasing_powerList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.purchasing_power_value = item.label;
                    }
                })
            },
            //选择促销敏感度
            selectPromotion_sensitivity(val) {
                this.promotion_sensitivityList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.promotion_sensitivity_value = item.label;
                    }
                })
            },
            //选择评论敏感度
            selectComment_sensitivity(val) {
                this.comment_sensitivityList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.comment_sensitivity_value = item.label;
                    }
                })
            },
            //选择粉丝类型
            selectFan_type(val) {
                this.fan_typeList.forEach(item => {
                    if (val === item.id) {
                        this.fanCharacteristicsForm.fan_type_value = item.label;
                    }
                })
            },
            //保存
            submitForm() {
                //验证粉丝特征名称
                let dialog_flag = 0;
                this.fanCharacteristicsTable.forEach((item,index) => {
                    if (this.editIndex !== index) {
                        if (item.name === this.fanCharacteristicsForm.name) {
                            dialog_flag++;
                            if (dialog_flag === 1) {
                                return this.$message.warning('粉丝特征名称不能重复');
                            }
                            return;
                        }
                    }
                });
                if (dialog_flag) {
                    return;
                }
                if (!this.fanCharacteristicsForm.name) {
                    return this.$message.warning('请输入客户名称！');
                }
                if (this.fanCharacteristicsForm.sex === null) {
                    return this.$message.warning('请选择性别！');
                }
                if (!this.fanCharacteristicsForm.area_id) {
                    return this.$message.warning('请选择地区！');
                }
                if (!this.fanCharacteristicsForm.member_level) {
                    return this.$message.warning('请选择会员等级！');
                }
                if (!this.fanCharacteristicsForm.purchasing_power) {
                    return this.$message.warning('请选择购买力！');
                }
                if (!this.fanCharacteristicsForm.promotion_sensitivity) {
                    return this.$message.warning('请选择促销敏感度！');
                }
                if (!this.fanCharacteristicsForm.comment_sensitivity) {
                    return this.$message.warning('请选择评论敏感度！');
                }
                if (!this.fanCharacteristicsForm.fan_type) {
                    return this.$message.warning('请选择粉丝类型！');
                }
                this.fanCharacteristicsForm.area_id = this.fanCharacteristicsForm.area_id.length === 2 ? this.fanCharacteristicsForm.area_id[1] : this.fanCharacteristicsForm.area_id;
                let res = [...this.allAreaList[0].children, ...this.allAreaList[1].children].find(item => item.id === this.fanCharacteristicsForm.area_id);
                this.fanCharacteristicsForm.area_value = res.name;
                if (this.editIndex === null) {
                    this.fanCharacteristicsTable.push(this.fanCharacteristicsForm);
                } else {
                    this.fanCharacteristicsTable.splice(this.editIndex, 1, this.fanCharacteristicsForm);
                    this.editIndex = null;
                }
                this.addFanCharacteristicsDialog = false;
            },
            //关闭添加弹窗
            handleCloseAddDialog() {
                this.addFanCharacteristicsDialog = false;
                this.fanCharacteristicsForm = {
                    name: '',
                    sex: '',
                    sex_value: '',
                    age: null,
                    area_id: null,
                    area_value: '',
                    member_level: '',
                    member_level_value: '',
                    purchasing_power: '',
                    purchasing_power_value: '',
                    promotion_sensitivity: '',
                    promotion_sensitivity_value: '',
                    comment_sensitivity: '',
                    comment_sensitivity_value: '',
                    fan_type: '',
                    fan_type_value: '',
                }
            },
            //编辑
            editFansBtn(row, index) {
                this.addFanCharacteristicsDialog = true;
                this.editIndex = index;
                this.fanCharacteristicsForm = {
                    name: row.name,
                    sex: row.sex,
                    sex_value: row.sex_value,
                    age: row.age,
                    area_id: [undefined, Number(row.area_id)],
                    area_value: row.area_value,
                    member_level: row.member_level,
                    member_level_value: row.member_level_value,
                    purchasing_power: row.purchasing_power,
                    purchasing_power_value: row.purchasing_power_value,
                    promotion_sensitivity: row.promotion_sensitivity,
                    promotion_sensitivity_value: row.promotion_sensitivity_value,
                    comment_sensitivity: row.comment_sensitivity,
                    comment_sensitivity_value: row.comment_sensitivity_value,
                    fan_type: row.fan_type,
                    fan_type_value: row.fan_type_value,
                }
            },
            //删除
            delBtn(index, row) {
                this.$confirm('是否删除该粉丝特征，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.fanCharacteristicsTable.splice(index, 1);
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //导入粉丝特征
            importFanCharacteristics() {
                this.$refs.excelInput.value = null;
                this.$refs.excelInput.click();
            },
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.excelFile = fileList;
                this.excelFileName = fileList.name;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(',') + 1);
                    that.keyword_excel = "data:excel/*," + url;
                }
                let formData = new FormData();
                formData.append('file', this.excelFile);
                fanImport(formData).then((res) => {
                    this.fanCharacteristicsTable = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //获取要导出的粉丝特征并下载
            exportFanCharacteristics() {
                let formData = new FormData();
                let fanArr = [];
                fanArr = this.fanCharacteristicsTable;
                formData.append('content', JSON.stringify(fanArr));
                fan1(formData).then((res) => {
                    window.open(localStorage.getItem('hosturl') + 'fan/export?key='+res.data.key);
                }).catch((err) => {
                    console.log(err);
                });
            },
            //下载模板
            downloadTemplate() {
                window.location.href = localStorage.getItem('hosturl') + 'uploads/fan_excel/fan_template.xls';

            },
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .header {
            padding: 0 20px;
        }
        .steps-wrapper {
            padding: 20px;
        }
        .create-content{
            flex: 1;
            padding: 0 20px;
            .create-content-top{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                .create-content-left{
                    /*::v-deep.el-button--primary.is-plain {*/
                    /*    color: #1CB064;*/
                    /*    background: transparent;*/
                    /*    border-color: #a4dfc1;*/
                    /*}*/
                }
                .create-content-right{

                    display: flex;
                    align-items: center;
                    .label-name{
                        min-width: 100px;
                    }
                }
                .name-input {
                    width: 325px;
                    ::v-deep .el-input__inner {
                        border-radius: 0;
                    }
                }
            }
            .add-footer{
                margin-top: 20px;
            }
            .success-content{
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 140px;
                .text{
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                }
            }
        }
        .create-table {
            height: 1%;
            display: flex;
            flex-direction: column;
        }
        .bottom-btn{
            text-align: center;
            padding: 20px;
        }
    }
    .add-item {
        display: flex;
        align-items: center;
        ::v-deep .el-form-item__label {
            margin-right: 10px;
            margin-bottom: 0;
            color: #666;
        }
        ::v-deep .el-form-item__content {
            flex: 1;
            width: 1%;
            margin-left: 0 !important;
            .el-select {
                width: 100%;
            }
        }
    }
</style>